export function useApplyToJob() {
   const router = useRouter();
   const route = useRoute();

   async function applyToJob(job: Position) {
      if (job.application_url) {
         await authFetch(POSITION_ENDPOINTS.APPLY(job.sync_id), { method: 'POST', body: { comment: 'Applied using NxtForm' } }).catch(() =>
            console.warn(`Failed to create hit`),
         );
         const isSameOriginUrl = job.application_url.startsWith(window.location.origin);
         return window.open(job.application_url, isSameOriginUrl ? '_self' : '_blank');
      }

      return router.push({ ...route, hash: `${GlobalModalKey.JobApply}?jobId=${job.sync_id}` });
   }

   return {
      applyToJob,
   };
}
